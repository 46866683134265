export const MENU = {
  ACCOUNT: '/account',
  TOP_UP: '/top-up',
  TOP_UP_HISTORY: '/top-up-history',
  ORDER_HISTORY: '/order-history',
  TICKET: '/tickets',
  PURCHASE: '/purchase/:id',
  TWO_FA: '/2fa',
  CUSTOMER_SUPPORT: '/customer-support',
};
export const ROUTES = {
  HOME: '/home',
  LOGIN: '/login',
  REGISTER: '/register',
  ACCOUNT: '/account',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:id/:languageRequest/:resetToken',
  CHECK_TOKEN_RESET_PASSWORD: '/check-token-reset-password',
  PURCHASE_HISTORY: '/purchase-history',
  PURCHASE_HISTORY_DETAIL: '/purchase-history/:id',
  DEPOSIT: 'deposit',
  DEPOSIT_HISTORY: 'deposit-history',
  TICKETS: 'tickets',
  CREATE_TICKET: 'tickets/create',
  EDIT_TICKET: 'tickets/:id',
  TWO_FA_TOOL: '/2fa',
  SUPPORT: 'support',
  CATEGORY_DETAIL: 'category/:id',
  TWO_FA_SETTING: '/auth-two-face',
  VERIFY_2FA_PAGE: '/verify-2fa',
};

export const ROUTESNOTVERIFY2FA = [
  '/login',
  '/register',
  '/forgot-password',
  '/verify-2fa',
  '/reset-password/:id/:languageRequest/:resetToken',
];
