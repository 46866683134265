import React, { createContext, useEffect, useReducer } from 'react';
import { AuthState, AuthContextType, User } from './types';
import { reducer } from './reducers';
import { initialize, updateUser } from './actions';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES, ROUTESNOTVERIFY2FA } from '../../utils/constants/routes';
import useNavigateSearch from '../../hooks/useNavigateSearch';
import axios, { AxiosRequestConfig } from 'axios';
import { ERR_NETWORK } from '../../utils/constants/https';
import authService from '../../services/auth/authService';
import { isTokenExpired } from '../../utils/helpers/function';
import { accessToken, getLanguageFromLocalStorage, getUserFromLocalStorage, setLanguageFromLocalStorage } from '../../utils/helpers/auth';
import { publicRouter } from '../configRouter/configRouter';
import i18n from 'src/utils/translation/i18n';
import { LANGUAGE } from 'src/utils/constants/enum';
import { useStoreGlobal } from 'src/hooks/useStoreGlobal';
import { KEY } from 'src/utils/configs/app';

interface AuthProviderProps {
  children: React.ReactNode;
}

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const searchParams = useNavigateSearch();
  const location = useLocation();
  const navigate = useNavigate();
  const { setLanguage, setLoading } = useStoreGlobal();

  const redirectLogin = async () => {
    const isPublicRoute = publicRouter.find((route) => route.path === location.pathname && !route.auth);

    if (!isPublicRoute) {
      const search = searchParams({
        prevURL: window.location.pathname ? window.location.pathname : ROUTES.HOME,
        searchURL: window.location.search,
      });

      const newState = { page: 'login' };

      window.history.replaceState(newState, 'Login', ROUTES.LOGIN);
      window.location.replace('redirect' + window.location.pathname !== ROUTES.LOGIN ? search : location.search);
      if (window.location.pathname !== ROUTES.LOGIN) {
      }
    }
  };

  const fetchDataUser = async () => {
    setLoading(true);
    try {
      const { user, token } = await authService.getUser();
      setLoading(false);
      if (token && isTokenExpired(token)) {
        redirectLogin();
        return;
      }
      dispatch(updateUser(user as User));
    } catch (error) {
      dispatch(
        initialize({
          isAuthenticated: false,
          user: null,
          isInitialized: true,
        }),
      );
      redirectLogin();
    }
    setLoading(false);
  };

  const loadApiUser = async () => {
    if (isTokenExpired(accessToken() || null)) {
      redirectLogin();
      return;
    }
  };

  const handleSetLanguage = async () => {
    const userInfo = await getUserFromLocalStorage();
    const language = localStorage.getItem(KEY.keyLanguage);
    setLanguage(userInfo?.language ?? language ?? LANGUAGE.VI);
    setLanguageFromLocalStorage(userInfo?.language ?? language ?? LANGUAGE.VI);
  };

  useEffect(() => {
    const isPublicRoute = publicRouter.find(
      (route) => (route.path === location.pathname || location.pathname.startsWith(route.path + '/')) && !route.auth,
    );

    if (location.pathname.startsWith('/reset-password')) {
      return;
    }
    if (!isPublicRoute) {
      loadApiUser();
    }
  }, [location]);

  useEffect(() => {
    axios.interceptors.request.use(
      (config: AxiosRequestConfig | any) => {
        const token = accessToken();

        if (token) {
          config.headers = {
            ...config.headers,
            'Content-Type': 'application/json',
            Authorization: token,
          };
        }

        return config;
      },
      (error) => {
        const { response } = error;
        if (ERR_NETWORK === error.code) {
          redirectLogin();
        }

        if (response && [403, 401].includes(response.status)) {
          redirectLogin();
          console.error(`API error: ${response.status}`);
        }

        return Promise.reject(error);
      },
    );

    fetchDataUser();
    handleSetLanguage();
  }, []);

  useEffect(() => {
    const isVerifyed = localStorage.getItem('load_common_js') === 'ok';
    const path = window.location.pathname;
    const isInRouteNotVerify2fa = ROUTESNOTVERIFY2FA.find((route) => route === path);
    const fetchUser = async () => {
      const { user, token } = await authService.getUser();
      if (!isVerifyed && !isInRouteNotVerify2fa && user?.isEnable2Fa === true) {
        navigate(ROUTES.VERIFY_2FA_PAGE);
      }
    };

    fetchUser();
  }, [window.location.pathname]);

  return <AuthContext.Provider value={{ ...state, dispatch }}>{children}</AuthContext.Provider>;
};

export default AuthContext;
