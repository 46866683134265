import React from 'react';
import './App.css';
import GlobalStyles from './styles/GlobalStyles/GlobalStyles';
import './styles/bases/Responsive.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { GlobalContextProvider } from './context/GlobalContext/GlobalContext';
import { AuthProvider } from './context/auth/AuthContext';
import Loading from './components/Loading';
import ConfigRouter from './context/configRouter/configRouter';
import { I18nextProvider } from 'react-i18next';
import i18n from './utils/translation/i18n';
import FloatingButton from './layout/FloatingButton';

interface ExtendedAliasToken {
  fontFamily?: string;
  fontSize?: number;
  color?: string; // Thêm thuộc tính màu sắc
}

function App() {
  return (
    <React.Fragment>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'Inter, sans-serif',
            fontSize: 14,
            color: '#496057',
          } as ExtendedAliasToken,
        }}
      >
        <I18nextProvider i18n={i18n}>
          <Router>
            <ConfigProvider>
              <GlobalContextProvider>
                <AuthProvider>
                  <div className="App">
                    <Loading>
                      <GlobalStyles>
                        <div className="container">
                          <ConfigRouter></ConfigRouter>
                        </div>
                      </GlobalStyles>
                    </Loading>
                  </div>
                </AuthProvider>
              </GlobalContextProvider>
            </ConfigProvider>
          </Router>
        </I18nextProvider>
        <FloatingButton />
      </ConfigProvider>
    </React.Fragment>
  );
}

export default App;
