import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { accessToken } from '../utils/helpers/auth';
import { KEY } from '../utils/configs/app';
import { ERR_NETWORK } from 'src/utils/constants/https';

const getClientIP = async () => {
  const response = await axios.get('https://api.ipify.org?format=json');
  const ip = response?.data?.ip || null;
  return ip;
};
const instance: AxiosInstance = axios.create({
  baseURL: KEY.BASE_URL,
});

instance.interceptors.request.use(
  async (config: AxiosRequestConfig | any) => {
    const token = accessToken();

    if (token) {
      config.headers = {
        ...config.headers,
        'Content-Type': 'application/json',
        Authorization: token,
      };
    }

    if (window.location.pathname == '/login') {
      const clientIP = await getClientIP();
      if (clientIP) {
        config.headers = {
          ...config.headers,
          'X-Client-IP': clientIP,
        };
      }
    }

    return config;
  },
  (error) => {
    if (ERR_NETWORK === error.code || [401, 403].indexOf(error.response.status) > -1) {
      localStorage.removeItem(KEY.keyAuthLocal);
      localStorage.removeItem(KEY.keyTokenLocal);
      if (window.location.pathname !== '/login') {
        window.location.href = '/login';
      }
    }

    return Promise.reject(error);
  },
);

export default instance;
