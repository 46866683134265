import { env } from 'process';

export const KEY = {
  keyAuthLocal: 'userInfo',
  keyTokenLocal: 'token',
  loginRemember: 'remember',
  keyLanguage: 'language',
  BASE_URL: process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://localhost:1337/via/',
  EXTERNAL_URL: process.env.REACT_APP_EXTERNAL ?? 'https://viahaiphong.com',
  EXTERNAL_API: process.env.REACT_APP_EXTERNAL_API ?? 'https://viahaiphong.com/api',
  INTERNAL_API: process.env.REACT_APP_INTERNAL_API ?? 'http://localhost:1337',
};
