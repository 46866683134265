import { useContext } from 'react';
import AuthContext from '../context/auth/AuthContext';
import { AuthContextType } from '../context/auth/types';

export function useAuth(): AuthContextType {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('Auth context must be inside AuthProvider');
  }
  return context;
}
