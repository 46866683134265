export enum REQUEST_METHOD {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
}

export enum LANGUAGE {
  VI = 'vi',
  ZH = 'zh',
}

export enum TYPE_MESSAGES {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export enum PAGINATION {
  LIMIT = 10,
}
