import React, { useContext, useEffect, useRef, useState } from 'react';
import '../styles/pages/header.scss';
import { Avatar, Button } from 'antd';
import { CloseOutlined, CreditCardOutlined, FileOutlined, LogoutOutlined, MenuOutlined, UserOutlined } from '@ant-design/icons';
import LanguageSelector from 'src/components/LanguageSelector';
import { useTranslation } from 'react-i18next';
import { useStoreGlobal } from 'src/hooks/useStoreGlobal';
import authService from 'src/services/auth/authService';
import { TYPE_MESSAGES } from 'src/utils/constants/enum';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/utils/constants/routes';
import { OpenToast } from 'src/utils/helpers/function';
// import Logo from 'src/assets/images/logo/logo.jpg';
import LogoText from 'src/assets/images/logo/logo_text.jpg';
import LogoShape from 'src/assets/images/logo/logo_shape.jpg';
import { useAuth } from 'src/hooks/useAuth';
import AuthContext from 'src/context/auth/AuthContext';
import { signOut } from 'src/context/auth/actions';

interface HeaderProps {
  isShowNav: boolean;
  setIsShowNav: (status: boolean) => void;
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { isShowNav, setIsShowNav } = props;
  const { t } = useTranslation();
  const wrapperRef: any = useRef(null);
  const [isDrop, setIsDrop] = useState(false);
  const { setLoading } = useStoreGlobal();
  const navigate = useNavigate();
  const auth = useAuth();
  const { user } = auth;
  const authContext = useContext(AuthContext);

  const openDropLogout = () => {
    setIsDrop(true);
  };

  const handleLogout = async () => {
    setLoading(true);
    const response = await authService.signOut();
    if (response?.isError) {
      OpenToast({ message: response?.message }, TYPE_MESSAGES.ERROR);
      setLoading(false);
      return;
    }
    authContext?.dispatch(signOut());

    navigate(ROUTES.LOGIN);
    setLoading(false);
  };
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !(wrapperRef.current as any).contains(event.target)) {
        setIsDrop(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="header shadow-border">
      <div className="header-body">
        <div className="content-header">
          <a className="flex items-center" href={ROUTES.HOME}>
            <img src={LogoShape} className="logo logo__shape" alt="" />
            <img src={LogoText} className="logo logo__text" alt="" />
          </a>
          <div className="header-right flex items-center">
            <div className="header-action">
              <div className="btn-avatar">
                <Avatar size={25} icon={<UserOutlined />} onClick={openDropLogout} />

                {isDrop && (
                  <div ref={wrapperRef} className="show__action blockDrop">
                    <div className="show__action-drop">
                      <div className="show__action-name">
                        <div className="show__action-name-content">
                          <Avatar
                            size={52}
                            style={{ backgroundColor: '#87d068', cursor: 'pointer' }}
                            icon={<UserOutlined />}
                            onClick={() => {
                              navigate(ROUTES.ACCOUNT), setIsDrop(false);
                            }}
                          />
                          <p
                            onClick={() => {
                              navigate(ROUTES.ACCOUNT), setIsDrop(false);
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            {user?.username}
                          </p>
                        </div>
                      </div>
                      <div className="show__drop-action">
                        <div className="av-drop-item">
                          <Button
                            className="btn-icon"
                            type="text"
                            icon={<UserOutlined />}
                            onClick={() => {
                              navigate(ROUTES.ACCOUNT), setIsDrop(false);
                            }}
                          >
                            {t('dropdown_avatar.account')}
                          </Button>
                        </div>
                        <div className="av-drop-item">
                          <Button
                            className="btn-icon"
                            type="text"
                            icon={<FileOutlined />}
                            onClick={() => {
                              navigate(ROUTES.PURCHASE_HISTORY), setIsDrop(false);
                            }}
                          >
                            {t('dropdown_avatar.transaction_history')}
                          </Button>
                        </div>
                        <div className="av-drop-item">
                          <Button className="btn-icon" type="text" icon={<CreditCardOutlined />}>
                            {t('dropdown_avatar.surplus')}
                          </Button>
                        </div>
                        <div className="dropdown-divider"></div>
                        <div className="av-drop-item btn-Logout">
                          <Button className="btn-icon" type="text" icon={<LogoutOutlined />} onClick={handleLogout}>
                            {t('dropdown_avatar.log_out')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="hamburger">
              <Button
                className="hamburger-button flex items-center"
                onClick={() => {
                  setIsShowNav(!isShowNav);
                }}
              >
                {isShowNav ? <CloseOutlined /> : <MenuOutlined />}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
