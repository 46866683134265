import { ApiRequest } from '../api/apiRequest';
import { KEY } from '../utils/configs/app';
import { LANGUAGE, REQUEST_METHOD } from '../utils/constants/enum';
import { PATH } from './Path';

class CategoryServices {
  async getAll() {
    const paramsFromUrl = new URLSearchParams(window.location.search);
    const language = paramsFromUrl.get('language') || LANGUAGE.VI;

    const { isError, data, status } = await ApiRequest(REQUEST_METHOD.GET, PATH.CATEGORIES + `?language=${language}`);
    if (isError) {
      return {
        status,
        isError,
      };
    }

    const categories = data.map((item: Category) => {
      return {
        id: item?.id,
        name: item?.name,
        icon: item?.icon,
        img_icon: item?.img_icon || null,
        list_product: item?.list_product,
      };
    });

    return {
      categories: categories,
    };
  }

  async findOne(id: number, values: any) {
    const { isError, data, status } = await ApiRequest(REQUEST_METHOD.GET, `${PATH.CATEGORIES}/${id}`, undefined, values);

    if (isError) {
      return {
        status,
        isError,
      };
    }

    return data;
  }

  async findMenuList() {
    const { isError, data, status } = await ApiRequest(REQUEST_METHOD.GET, PATH.CATEGORIES);

    if (isError) {
      return {
        status,
        isError,
      };
    }

    const categories = data.map((item: Category) => {
      return {
        id: item?.id,
        icon: item?.icon,
        img_icon: item?.img_icon || null,
        name: item.name,
        list_product: item?.list_product,
      };
    });

    return {
      categories: categories,
    };
  }
}

const categoryServices = new CategoryServices();

export default categoryServices;
