import { KEY } from 'src/utils/configs/app';
import { PayloadAction, ReducerHandler, AuthActionType } from './types';
import { AuthState } from './types';
import { clearLocalStorage, setAccessToken, setLanguageFromLocalStorage, setUserLocal } from 'src/utils/helpers/auth';
import { set } from 'lodash';
import { LANGUAGE } from 'src/utils/constants/enum';

const reducerHandlers: ReducerHandler = {
  [AuthActionType.INITIALIZE]: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    localStorage.setItem(KEY.keyAuthLocal, user ? JSON.stringify(user) : '');
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },

  [AuthActionType.SIGN_IN]: (state, action) => {
    const { user } = action.payload;
    // console.log('user', user);

    if (user) {
      setLanguageFromLocalStorage(user.language || LANGUAGE.VI);
      setUserLocal(user);
      setAccessToken(user.token || null);
    }
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },

  [AuthActionType.SIGN_OUT]: (state) => {
    clearLocalStorage();
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  },

  [AuthActionType.UPDATE_USER]: (state, action) => {
    const { user } = action.payload;
    if (user) {
      setLanguageFromLocalStorage(user.language || LANGUAGE.VI);
      setUserLocal(user);
      setAccessToken(user.token || null);
    }
    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

export function reducer(state: AuthState, action: PayloadAction): AuthState {
  const handler = reducerHandlers[action.type];
  return handler ? handler(state, action) : state;
}
