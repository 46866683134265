import React, { ReactNode, useEffect, useState } from 'react';
import NavBar from './NavBar';
import '../styles/layout/DefaultLayout.scss';
import Header from './Header';
import { accessToken } from 'src/utils/helpers/auth';
import { Outlet, useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/utils/constants/routes';

const DefaultLayout = () => {
  const [isShowNav, setIsShowNav] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    if (!accessToken()) {
      navigate(ROUTES.LOGIN);
    }
  }, []);

  return (
    <aside>
      <Header isShowNav={isShowNav} setIsShowNav={setIsShowNav} />
      <div className="virtual-headers"></div>
      <div className="vi-container">
        <NavBar isShowNav={isShowNav} setIsShowNav={setIsShowNav} />
        <div className={`vi-content ${isShowNav ? 'width-body-show-nav' : 'width-body-hide-nav'}`}>
          <Outlet />
        </div>
      </div>
    </aside>
  );
};

export default DefaultLayout;
