import { notification } from 'antd';
import { LANGUAGE, TYPE_MESSAGES } from '../constants/enum';
import { ArgsProps } from '../../interfaces/common/common';
import { useStoreGlobal } from 'src/hooks/useStoreGlobal';
type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const OpenToast = (config: ArgsProps, type: NotificationType) => {
  switch (type) {
    case TYPE_MESSAGES.SUCCESS:
      notification.success(config);

      break;
    case TYPE_MESSAGES.ERROR:
      notification.error(config);

      break;
    case TYPE_MESSAGES.WARNING:
      notification.warning(config);

      break;

    default:
      notification.open(config);
      break;
  }
};

export function isTokenExpired(jwtToken: string | null) {
  if (!jwtToken || typeof jwtToken !== 'string' || jwtToken === undefined) {
    return true;
  }

  const tokenParts = jwtToken.split('.');
  if (tokenParts.length !== 3) {
    return true;
  }

  const payloadBase64 = tokenParts[1];
  const decodedPayload = JSON.parse(atob(payloadBase64));

  if (!decodedPayload.exp) {
    return true;
  }

  const currentTime = Math.floor(Date.now() / 1000);
  return decodedPayload.exp < currentTime;
}

export function escapeRegExp(value: string): string {
  return value.replace(/[\!\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\_\-]/g, '\\$&');
}

export const searchStringOfArray = (searchString: string, key: string, arr: any[]) => {
  const lowerCaseSainString = searchString.trim().toLocaleLowerCase();
  const escapeMainString = escapeRegExp(lowerCaseSainString);

  return (
    arr.filter((item) => {
      const regex = new RegExp(escapeMainString, 'i');
      const mainString = item[key].toLocaleLowerCase();
      return regex.test(mainString);
    }) ?? []
  );
};

export const convertVndToUsd = (vnd: number) => {
  const coefficient = Number(process.env.REACT_APP_MONEY_COEFFICIENT) || 0.000043;
  const usd = vnd * coefficient;

  if (Number.isInteger(usd)) {
    return usd;
  } else {
    const phanThapPhan = usd.toString().split('.')[1];
    if (phanThapPhan) {
      const soSauDauPhay = parseFloat(`0.${phanThapPhan}`).toFixed(6);
      return parseFloat(`${usd.toString().split('.')[0]}.${soSauDauPhay.toString().split('.')[1]}`);
    } else {
      return usd;
    }
  }
};
