import { PayloadAction, AuthState, User } from './types';
import { AuthActionType } from './types';

export function initialize(payload: AuthState): PayloadAction {
  return {
    type: AuthActionType.INITIALIZE,
    payload,
  };
}

export function signIn(payload: AuthState): PayloadAction {
  return {
    type: AuthActionType.SIGN_IN,
    payload,
  };
}

export function signOut(): PayloadAction {
  return {
    type: AuthActionType.SIGN_OUT,
    payload: {
      user: null,
      isAuthenticated: false,
      isInitialized: false,
    },
  };
}

export const updateUser = (user: User): PayloadAction => ({
  type: AuthActionType.UPDATE_USER,
  payload: {
    user,
    isAuthenticated: true,
    isInitialized: true,
  },
});
