import { User } from '../../context/auth/types';
import { ApiRequest } from '../../api/apiRequest';
import { KEY } from '../../utils/configs/app';
import { REQUEST_METHOD } from '../../utils/constants/enum';
import { PATH } from '../Path';
import { setAccessToken } from 'src/utils/helpers/auth';

export interface AuthDto {
  accessToken: string;
  user: User;
}
export interface SignInCredentials {
  username: string;
  password: string;
}

class AuthService {
  async signIn(value: any) {
    const response = await ApiRequest(REQUEST_METHOD.POST, PATH.LOGIN, value, false, false);

    return Promise.resolve({
      token: response?.token,
      user: response?.user,
      isError: response?.isError,
      message: response?.message,
    });
  }

  async signOut() {
    const response = await ApiRequest(REQUEST_METHOD.POST, PATH.LOGOUT);
    return Promise.resolve({
      isError: response?.isError,
      message: response?.message,
    });
  }

  async getUser() {
    const userStore = localStorage.getItem(KEY.keyAuthLocal);
    const token = localStorage.getItem(KEY.keyTokenLocal);
    const parseUser = userStore ? JSON.parse(userStore) : { user: null, token: '' };

    if (!userStore || !parseUser) {
      return {
        status: 403,
        isError: true,
      };
    }
    const { isError, data, status } = await ApiRequest(REQUEST_METHOD.GET, `${PATH.USER}${parseUser.id}`);

    if (isError) {
      return {
        status,
        isError,
      };
    }

    if (data) {
      return {
        token: token,
        user: {
          email: data.email,
          id: data.id,
          username: data.username,
          language: data.language,
          balance: data.balance,
          token: token,
          isEnable2Fa: data?.isEnable2FA ?? false,
          trc_20_address: data?.trc_20_address ?? '',
        },
      };
    } else {
      return {
        token: null,
        user: null,
      };
    }
  }

  async forgotPassword(value: any) {
    const response = await ApiRequest(REQUEST_METHOD.POST, PATH.FORGOT_PASSWORD, value, false, false);

    return Promise.resolve({
      isError: response?.isError,
      message: response,
    });
  }
}

const authService = new AuthService();

export default authService;
