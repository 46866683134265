import { KEY } from '../utils/configs/app';

export const PATH = {
  USER: 'auth/user/',
  LOGIN: 'auth/login',
  LOGOUT: 'auth/logout',
  CHANGE_PASSWORD: 'auth/change-password',
  REGISTER: 'auth/register',
  FORGOT_PASSWORD: 'auth/forgot-password',
  CHECK_TOKEN: 'auth/check-token-forgot-password',
  RESET_PASSWORD: 'auth/reset-password',
  CHANGE_LANGUAGE: 'auth/change-language',
  CREATE_ORDER: 'binance-order/create',
  MENU_LISTS: 'findMenuList',
  CATEGORIES: 'via-categories',
  PRODUCT_TYPES: 'via-product-types',
  SUPPORT_TICKETS: 'via-support-tickets',
  CREATE_TRANSACTION: 'category/buy',
  TRANSACTIONS: 'via-transactions',
  CHECK_ORDER: 'binance-order/check-order',
  TRANSACTIONS_TICKET: 'via-transactions/getAll',
  MY_ORDER: 'binance-order/my-orders',
  GENERATE_2FA: '2fa/generate',
  VERIFY_2FA: '2fa/verify',
  DISABLE_2FA: '2fa/disable',
  VERIFY_2FA_TO_ACCESS_PAGE: '2fa/verify-to-access-page',
  LOGIN_HISTORY: 'login-histories',
  RE_CATEGORIES: 're-categories',
  NOTIFICATION: 'via-notifications',
  INFORMATION_CONTACT: 'information-contact-all',
  UPDATE_TRON_ADDRESS: 'update-address-trc20',

  EXTERNAL_API: {
    CATEGORIES: `${KEY.EXTERNAL_API}/categories`,
    CATEGORY_DETAIL: `${KEY.EXTERNAL_API}/category`,
  },
};
