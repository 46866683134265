import React, { useEffect, useState } from 'react';
import '../styles/pages/header.scss';
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  CreditCardOutlined,
  DollarOutlined,
  FileDoneOutlined,
  HistoryOutlined,
  HomeOutlined,
  PhoneOutlined,
  ShoppingCartOutlined,
  ToolOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Avatar, Badge, Button, Menu, MenuProps, Tooltip } from 'antd';
import '../styles/layout/NavBar.scss';
import { ROUTES } from 'src/utils/constants/routes';
import { useNavigate } from 'react-router-dom';
import { useStoreGlobal } from 'src/hooks/useStoreGlobal';
import { OpenToast } from 'src/utils/helpers/function';
import { useAuth } from 'src/hooks/useAuth';
import ViFlag from '../assets/images/quoc-ky-viet-nam.png';
import ZhFlag from '../assets/images/quoc-ky-trung-quoc.png';
import { ApiRequest } from 'src/api/apiRequest';
import { LANGUAGE, REQUEST_METHOD, TYPE_MESSAGES } from 'src/utils/constants/enum';
import { PATH } from 'src/services/Path';
import { KEY } from 'src/utils/configs/app';
import { renderMessage } from 'src/utils/helpers/untils';
import authService from 'src/services/auth/authService';
import { updateUser } from 'src/context/auth/actions';
import { User } from 'src/context/auth/types';
import IconDefault from '../assets/icons/icon_default.svg';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[], type?: 'group'): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

interface NavBarProps {
  isShowNav: boolean;
  setIsShowNav: (status: boolean) => void;
}

const NavBar = (props: NavBarProps) => {
  const { setLoading, language, setLanguage, categories } = useStoreGlobal();
  const { isShowNav, setIsShowNav } = props;
  const [isTablet, setIsTablet] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth = useAuth();
  const { user } = auth;
  const { dispatch } = useAuth();

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsShowNav(false);
      setIsTablet(true);
    } else {
      setIsShowNav(true);
      setIsTablet(false);
    }
  };

  useEffect(() => {
    handleResize();

    // Set up event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  const items: MenuProps['items'] = [
    getItem(
      `${t('navbar.home')}`,
      ROUTES.HOME,
      <Tooltip title={isShowNav ? '' : t('navbar.home')} placement="right">
        <HomeOutlined />
      </Tooltip>,
    ),
    getItem(
      `${isShowNav ? t('navbar.bank') : ''}`,
      'grp1',
      null,
      [
        getItem(
          `${t('navbar.deposit_money')}`,
          ROUTES.DEPOSIT,
          <Tooltip title={isShowNav ? '' : t('navbar.deposit_money')} placement="right">
            <DollarOutlined />
          </Tooltip>,
        ),
        getItem(
          `${t('navbar.deposit_history')}`,
          ROUTES.DEPOSIT_HISTORY,
          <Tooltip title={isShowNav ? '' : t('navbar.deposit_history')} placement="right">
            <CreditCardOutlined />
          </Tooltip>,
        ),
        getItem(
          `${t('navbar.purchase_history')}`,
          ROUTES.PURCHASE_HISTORY,
          <Tooltip title={isShowNav ? '' : t('navbar.purchase_history')} placement="right">
            <HistoryOutlined />
          </Tooltip>,
        ),
        getItem(
          `${t('navbar.submit_ticket')}`,
          ROUTES.TICKETS,
          <Tooltip title={isShowNav ? '' : t('navbar.submit_ticket')} placement="right">
            <FileDoneOutlined />
          </Tooltip>,
        ),
        getItem(
          `${t('navbar.purchase')}`,
          'sub1',
          <Tooltip title={isShowNav ? '' : t('navbar.purchase')} placement="right">
            <ShoppingCartOutlined style={{ marginLeft: isShowNav ? 0 : '-14px' }} />
          </Tooltip>,
          categories &&
            categories
              .filter((category: Category) => category?.list_product && category?.list_product?.length > 0)
              .map((category: Category) => {
                return getItem(
                  <Tooltip title={category.name} placement="top">
                    {category.name}
                  </Tooltip>,
                  ROUTES.CATEGORY_DETAIL.replace(':id', String(category?.id)),
                  <Tooltip title={isShowNav ? '' : category.name} placement="right">
                    <div className="nav-via-icon">
                      <img
                        src={`${category?.img_icon ? KEY.INTERNAL_API + category?.img_icon?.url : category?.icon ? KEY.EXTERNAL_URL + '/' + category?.icon : IconDefault}`}
                        alt=""
                      />
                    </div>
                  </Tooltip>,
                );
              }),
        ),
      ],
      'group',
    ),
    getItem(
      `${isShowNav ? t('navbar.tools') : ''}`,
      'grp2',
      null,
      [
        getItem(
          `${t('navbar.2fa_tool')}`,
          ROUTES.TWO_FA_TOOL,
          <Tooltip title={isShowNav ? '' : t('navbar.2fa_tool')} placement="right">
            <ToolOutlined />
          </Tooltip>,
        ),
        getItem(
          `${t('navbar.support')}`,
          ROUTES.SUPPORT,
          <Tooltip title={isShowNav ? '' : t('navbar.support')} placement="right">
            <PhoneOutlined />
          </Tooltip>,
        ),
      ],
      'group',
    ),
  ];

  const onClick: MenuProps['onClick'] = (e) => {
    navigate(e.key);
    if (isTablet) {
      setIsShowNav(false);
    }
  };

  const changeLanguage = async (value: string) => {
    setLoading(true);
    const response = await ApiRequest(REQUEST_METHOD.POST, PATH.CHANGE_LANGUAGE, { language: value });
    if (response?.isError) {
      OpenToast({ message: response?.message }, TYPE_MESSAGES.ERROR);
      setLoading(false);
      return;
    }
    setLanguage(value);
    localStorage.setItem(KEY.keyLanguage, value);
    OpenToast({ message: renderMessage('MSE000005') }, TYPE_MESSAGES.SUCCESS);
    setLoading(false);

    const { user } = await authService.getUser();
    dispatch(updateUser(user as User));
    window.location.reload();
  };

  useEffect(() => {
    if (isShowNav) {
      const antItem = document.querySelector('.ant-menu-item');
      const antItemSub = document.querySelector('.ant-menu-sub');
      if (antItem && antItem?.parentNode) {
        const parentNode = antItem.parentNode as HTMLElement;
        parentNode.classList.remove('hide-padding-left-item-nav');
        parentNode.classList.remove('ant-menu-submenu-arrow-hide');
      }
      if (antItemSub && antItemSub?.parentNode) {
        const parentNode = antItemSub.parentNode as HTMLElement;
        parentNode.classList.remove('custom-width-sub-nav');
      }
    } else {
      const antItem = document.querySelector('.ant-menu-item');
      const antItemSub = document.querySelector('.ant-menu-sub');
      if (antItem && antItem?.parentNode) {
        const parentNode = antItem.parentNode as HTMLElement;
        parentNode.classList.add('hide-padding-left-item-nav');
        parentNode.classList.add('ant-menu-submenu-arrow-hide');
      }
      if (antItemSub && antItemSub?.parentNode) {
        const parentNode = antItemSub.parentNode as HTMLElement;
        parentNode.classList.add('custom-width-sub-nav');
      }
    }
  }, [isShowNav]);

  return (
    <div className={`vi-navbar ${isShowNav ? 'width-show-nav' : 'width-hide-nav'}`}>
      {isShowNav && (
        <div className="vi-navbar__information">
          <div className="vi-navbar__information--avatar">
            <Avatar size={64} icon={<UserOutlined />} onClick={() => navigate(ROUTES.ACCOUNT)} style={{ cursor: 'pointer' }} />
          </div>
          <div
            className="vi-navbar__information--key-name"
            onClick={() => navigate(ROUTES.ACCOUNT)}
            style={{ cursor: 'pointer' }}
          >{`${user?.username ?? '-'} ID: ${user?.id ?? '-'}`}</div>
          <div className="vi-navbar__information--status">
            <Badge count={t('navbar.member')} showZero color="#0665d0" />
            <Badge count={`${t('common.balance')}: ${user?.balance ?? 0}`} showZero color="#3c90df" className="max-w-[60%]" />
          </div>
          <div className="vi-navbar__language">
            <div className="flex mx-auto justify-center mt-4">
              <div
                className={`w-[25px] h-[25px] overflow-hidden mr-5 cursor-pointer pb-[30px] ${
                  language === LANGUAGE.VI ? 'border-b-2 border-yellow' : ''
                }`}
                onClick={() => changeLanguage(LANGUAGE.VI)}
              >
                <img src={ViFlag} alt="" className="object-fill" />
              </div>
              <div
                className={`w-[25px] h-[25px] overflow-hidden cursor-pointer pb-[30px] ${language === LANGUAGE.ZH ? 'border-b-2 border-yellow' : ''}`}
                onClick={() => {
                  changeLanguage(LANGUAGE.ZH);
                }}
              >
                <img src={ZhFlag} alt="" className="object-fill" />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="vi-navbar__nav">
        <Menu onClick={onClick} defaultSelectedKeys={['1']} defaultOpenKeys={['sub1']} mode="inline" items={items} />
      </div>
      <div
        className={`vi-navbar__action ${isShowNav ? 'width-show-nav' : 'width-hide-nav'}`}
        onClick={() => {
          setIsShowNav(!isShowNav);
        }}
      >
        <Button type="text" icon={isShowNav ? <CaretLeftOutlined /> : <CaretRightOutlined />}></Button>
      </div>
    </div>
  );
};

export default NavBar;
