import React from 'react';
import { useStoreGlobal } from '../hooks/useStoreGlobal';
import { Spin } from 'antd';

function Loading(props: any) {
  const storeGlobal = useStoreGlobal();

  return (
    <Spin spinning={storeGlobal.loading} wrapperClassName="spinning-center">
      {props.children}
    </Spin>
  );
}

export default Loading;
