import { isTokenExpired } from './function';
import { User } from '../../context/auth/types';
import { KEY } from '../configs/app';
import { LANGUAGE } from '../constants/enum';
import { clear } from 'console';

export const accessToken = () => {
  const token = localStorage.getItem(KEY.keyTokenLocal);

  if (!token || isTokenExpired(token)) {
    return null;
  }

  return `Bearer ${token}`;
};

export const setAccessToken = (token: string | null) => {
  if (token) {
    localStorage.setItem(KEY.keyTokenLocal, token);
  }
};

export const setUserLocal = (user: User) => {
  localStorage.setItem(KEY.keyAuthLocal, user && typeof user === 'object' ? JSON.stringify(user) : '');
};

export const getUserFromLocalStorage = () => {
  try {
    // Get the user data from localStorage
    const userDataString = localStorage.getItem(KEY.keyAuthLocal);

    // If there is no user data, return null or handle it as needed
    if (!userDataString) {
      return null;
    }

    // Parse the user data from JSON
    const userData = JSON.parse(userDataString);

    return userData as User;
  } catch (error) {
    // Handle any potential errors (e.g., invalid JSON)
    console.error('Error getting user data from localStorage:', error);
    return null;
  }
};

export const setLanguageFromLocalStorage = async (language: string) => {
  if (language !== LANGUAGE.VI && language !== LANGUAGE.ZH) {
    localStorage.setItem(KEY.keyLanguage, LANGUAGE.VI);
    return;
  }
  localStorage.setItem(KEY.keyLanguage, language);
};

export const getLanguageFromLocalStorage = () => {
  try {
    // Get the user data from localStorage
    const language = localStorage.getItem(KEY.keyLanguage);

    // If there is no user data, return null or handle it as needed
    if (!language) {
      return LANGUAGE.VI;
    }

    return language;
  } catch (error) {
    console.error('Error getting language data from localStorage:', error);
    return LANGUAGE.VI;
  }
};

export const clearLocalStorage = () => {
  localStorage.removeItem(KEY.keyAuthLocal);
  localStorage.removeItem(KEY.keyTokenLocal);
  localStorage.removeItem('load_common_js');
};
