export interface AuthState {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: User | null;
}

export interface User {
  id?: number;
  username?: string;
  email?: string;
  password?: string;
  confirm_password?: string;
  language?: string;
  token?: string;
  createdAt?: string;
  binance?: string;
  balance?: number;
  isEnable2FA?: boolean;
  trc_20_address?: string;
}

export interface AuthContextType extends AuthState {
  dispatch: React.Dispatch<PayloadAction>;
}

export enum AuthActionType {
  INITIALIZE = 'INITIALIZE',
  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT',
  UPDATE_USER = 'UPDATE_USER',
}

export interface PayloadAction<T = AuthState> {
  type: AuthActionType;
  payload: T;
}

export interface ReducerHandler {
  [AuthActionType.INITIALIZE](state: AuthState, action: PayloadAction): AuthState;
  [AuthActionType.SIGN_IN](state: AuthState, action: PayloadAction): AuthState;
  [AuthActionType.SIGN_OUT](state: AuthState, action: PayloadAction): AuthState;
  [AuthActionType.UPDATE_USER](state: AuthState, action: PayloadAction): AuthState;
}
