import { useTranslation } from 'react-i18next';
import viMessage from '../message/vi/message.json';
import zhMessage from '../message/zh/message.json';
import i18n from '../translation/i18n';

type MessageObject = { [key: string]: string };

export const renderMessage = (messageID: string, arrs?: string[]) => {
  const currentLanguage = i18n.language;

  let message = '';

  if (currentLanguage === 'vi') {
    message = (viMessage as MessageObject)[messageID] || '';
  } else {
    message = (zhMessage as MessageObject)[messageID] || '';
  }

  if (arrs) {
    for (let index = 0; index < arrs.length; index++) {
      message = message.replace('{' + index + '}', arrs[index]);
    }
  }

  return message;
};
