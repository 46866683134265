import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../utils/constants/routes';
import { Spin } from 'antd';
import DefaultLayout from '../../layout/DefaultLayout';

export const publicRouter = [
  {
    path: '/',
    element: lazy(() => import('../../pages/Redirect')),
    auth: false,
  },
  {
    path: '*',
    element: lazy(() => import('../../pages/NotFound')),
    auth: false,
  },
  {
    path: ROUTES.LOGIN,
    element: lazy(() => import('../../pages/Login')),
    auth: false,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    element: lazy(() => import('../../pages/ForgotPassword')),
    auth: false,
  },
  {
    path: ROUTES.RESET_PASSWORD,
    element: lazy(() => import('../../pages/ResetPassword')),
    auth: false,
  },
  {
    path: ROUTES.CHECK_TOKEN_RESET_PASSWORD,
    element: lazy(() => import('../../pages/ResetPassword')),
    auth: false,
  },
  {
    path: ROUTES.REGISTER,
    element: lazy(() => import('../../pages/Register')),
    auth: false,
  },
];

export const privateRouter = [
  {
    path: ROUTES.HOME,
    element: lazy(() => import('../../pages/Home')),
    auth: true,
  },
  {
    path: ROUTES.ACCOUNT,
    element: lazy(() => import('../../pages/Account')),
    auth: true,
  },
  {
    path: ROUTES.DEPOSIT,
    element: lazy(() => import('../../pages/Deposit')),
    auth: true,
  },
  {
    path: ROUTES.DEPOSIT_HISTORY,
    element: lazy(() => import('../../pages/DepositHistory')),
    auth: true,
  },
  {
    path: ROUTES.PURCHASE_HISTORY,
    element: lazy(() => import('../../pages/PurchaseHistory')),
    auth: true,
  },
  {
    path: ROUTES.PURCHASE_HISTORY_DETAIL,
    element: lazy(() => import('../../pages/PurchaseHistoryDetail')),
    auth: true,
  },
  {
    path: ROUTES.TICKETS,
    element: lazy(() => import('../../pages/Tickets')),
    auth: true,
  },
  {
    path: ROUTES.CREATE_TICKET,
    element: lazy(() => import('../../pages/Ticket')),
    auth: true,
  },
  {
    path: ROUTES.EDIT_TICKET,
    element: lazy(() => import('../../pages/Ticket')),
    auth: true,
  },
  {
    path: ROUTES.TWO_FA_TOOL,
    element: lazy(() => import('../../pages/TwoFaTool')),
    auth: true,
  },
  {
    path: ROUTES.SUPPORT,
    element: lazy(() => import('../../pages/Support')),
    auth: true,
  },
  {
    path: ROUTES.CATEGORY_DETAIL,
    element: lazy(() => import('../../pages/CategoryDetail')),
    auth: true,
  },
  {
    path: ROUTES.TWO_FA_SETTING,
    element: lazy(() => import('../../pages/TwoFaSetting')),
    auth: true,
  },
  {
    path: ROUTES.VERIFY_2FA_PAGE,
    element: lazy(() => import('../../pages/Verify2FaPolice')),
    auth: true,
  },
];

const ConfigRouter: React.FC = () => {
  return (
    <Routes>
      {publicRouter.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={
            <React.Suspense
              fallback={
                <Spin spinning wrapperClassName="spinning-route">
                  <div
                    style={{
                      width: '100vw',
                      height: '100vh',
                    }}
                  />
                </Spin>
              }
            >
              {<route.element />}
            </React.Suspense>
          }
        />
      ))}

      {/* Private Routes */}
      <Route element={<DefaultLayout />}>
        {privateRouter.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <React.Suspense
                fallback={
                  <Spin spinning wrapperClassName="spinning-route">
                    <div
                      style={{
                        width: '100vw',
                        height: '100vh',
                      }}
                    />
                  </Spin>
                }
              >
                {<route.element />}
              </React.Suspense>
            }
          />
        ))}
      </Route>
    </Routes>
  );
};

export default ConfigRouter;
