import React, { createContext, ReactNode, useState, Dispatch, SetStateAction, useEffect } from 'react';
import categoryServices from 'src/services/categoryService';
import { LANGUAGE } from 'src/utils/constants/enum';
import i18n from 'src/utils/translation/i18n';

interface GlobalContextProps {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  language: string;
  setLanguage: (newLanguage: string) => void; // Update the type of setLanguage
  categories: Category[];
  fetchCategories: Dispatch<SetStateAction<Category[]>>;
}

const GlobalContext = createContext<GlobalContextProps | undefined>({
  loading: false,
  setLoading: () => { },
  language: LANGUAGE.VI,
  setLanguage: () => { },
  categories: [],
  fetchCategories: () => { }
});

interface GlobalContextProviderProps {
  children: ReactNode;
}

const GlobalContextProvider: React.FC<GlobalContextProviderProps> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState<string>(LANGUAGE.VI);
  const [categories, setCategories] = useState([])

  const changeLanguage = (newLanguage: string) => {
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  const fetchCategories = async () => {
    try {
      const response = await categoryServices.findMenuList();

      if (response?.isError) {
        return;
      }

      setCategories(response?.categories);
    } catch (error) {
      console.error('Error fetching data categories:', error);
    }
  }

  useEffect(() => {
    fetchCategories();
  }, []);

  return <GlobalContext.Provider value={{
    loading, setLoading,
    language, setLanguage: changeLanguage,
    categories, fetchCategories: fetchCategories
  }}
  >
    {children}
  </GlobalContext.Provider>;
};

export { GlobalContext, GlobalContextProvider };
