import React from 'react';
import IconPhone from '../assets/icons/phone.svg';
import '../styles/layout/FloatingButton.scss';

const FloatingButton: React.FC = () => {
  const handleClick = () => {
    window.open('https://t.me/tranquanghai0402', '_blank');
  };

  return (
    <div className="floating-button">
      <img src={IconPhone} alt="Phone Icon" onClick={handleClick} />
    </div>
  );
};

export default FloatingButton;
